import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPageDTO } from '../dto/page/page.dto';
import { IMediaDTO } from '../dto/media/media.dto';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public fetchMediaByUrl(
    url: string,
    recaptchaToken: string
  ): Observable<IMediaDTO> {
    const httpParams: HttpParams = new HttpParams()
      .append('url', encodeURIComponent(url))
      .append('gr', recaptchaToken);
    return this._httpClient.get<IMediaDTO>('/api/v1/public/medias', {
      withCredentials: true,
      params: httpParams,
    });
  }

  public fetchMedias(next?: string): Observable<IPageDTO<IMediaDTO>> {
    let httpParams: HttpParams = new HttpParams();
    if (next) {
      httpParams = httpParams.append('nextPageId', next);
    }
    return this._httpClient.get<IPageDTO<IMediaDTO>>('/api/v1/private/medias', {
      withCredentials: true,
      params: httpParams,
    });
  }
}
